import AppRouter from './AppRouter';


import 'aos/dist/aos.css';

function App() {
  return (
    <AppRouter />
  );
}

export default App;
