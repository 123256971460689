import React from "react";

function TermsAndConditions() {
  return (
    <div id="terms" className="modal fade" role="dialog" aria-hidden="true">
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Terms of Use</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <h5>Introduction</h5>
            <p>
              Welcome to Evara Technologies Pvt. Ltd. These terms and conditions
              outline the rules and regulations for the use of our services. By
              accessing and using our services, you accept these terms and
              conditions in full.
            </p>

            <h5>Services Provided</h5>
            <p>
              Evara Technologies Pvt. Ltd. offers a range of IT services
              including, but not limited to, website design and development,
              software development, digital marketing, cloud services, and
              technical staffing.
            </p>

            <h5>User Responsibilities</h5>
            <ul>
              <li>
                Users must provide accurate and complete information when
                registering for our services.
              </li>
              <li>
                Users are responsible for maintaining the confidentiality of
                their account information.
              </li>
              <li>
                Users must not use our services for any illegal or unauthorized
                purpose.
              </li>
            </ul>

            <h5>Payment Terms</h5>
            <ul>
              <li>
                Payment for services must be made in accordance with the terms
                specified in the invoice.
              </li>
              <li>
                Late payments may incur additional charges as specified in the
                invoice.
              </li>
            </ul>

            <h5>Intellectual Property</h5>
            <ul>
              <li>
                All content, trademarks, and data on our website and services
                are the property of Evara Technologies Pvt. Ltd. or its
                licensors.
              </li>
              <li>
                Users are granted a limited license to access and use our
                services for personal or business purposes.
              </li>
            </ul>

            <h5>Confidentiality</h5>
            <ul>
              <li>
                Both parties agree to keep all confidential information
                disclosed during the term of the agreement confidential and not
                to disclose it to any third party without prior written consent.
              </li>
            </ul>

            <h5>Limitation of Liability</h5>
            <ul>
              <li>
              Evara Technologies Pvt. Ltd. will not be liable for any indirect, incidental, or consequential damages arising out of or in connection with the use of our services.
              </li>
            </ul>

            <h5>Termination</h5>
            <ul>
              <li>
              We reserve the right to terminate or suspend access to our services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms.
              </li>
            </ul>

            <h5>Changes to Terms</h5>
            <ul>
              <li>
              Evara Technologies Pvt. Ltd. reserves the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting on our website.              </li>
            </ul>

            <h5>Contact Information</h5>
            <ul>
              <li>
              If you have any questions about these terms and conditions, please contact us at <a href="mailto:contact@evara-technologies.com">contact@evara-technologies.com</a>.</li>
              </ul>

          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
