import React from "react";

function PrivacyPolicy() {
  return (
    <div id="privacy" className="modal fade" role="dialog" aria-hidden="true">
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Privacy Policy</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <h5>Introduction</h5>
            <p>
              Evara Technologies Pvt. Ltd. (“we”, “our”, “us”) is committed to
              protecting and respecting your privacy. This Privacy Policy
              explains how we collect, use, disclose, and safeguard your
              information when you visit our website and use our services.
            </p>
            <h5>Information We Collect</h5>
            <p>We may collect and process the following data about you:</p>
            <ul>
              <li>
                Personal Identification Information: Name, email address, phone
                number, etc.
              </li>
              <li>
                Technical Data: IP address, browser type, operating system, and
                other technical information.
              </li>
              <li>
                Usage Data: Information about how you use our website and
                services.
              </li>
              <li>
                Cookies and Tracking Technologies: We use cookies to enhance
                your experience on our website.
              </li>
            </ul>
            <h5>How We Use Your Information</h5>
            <p>We use the information we collect in the following ways:</p>
            <ul>
              <li>To provide, operate, and maintain our services.</li>
              <li>To improve, personalize, and expand our services.</li>
              <li>
                To communicate with you, including customer service and support.
              </li>
              <li>To process transactions and send you related information.</li>
              <li>
                To send you promotional materials and updates (with your
                consent)
              </li>
            </ul>
            <h5>Sharing Your Information</h5>
            <p>
              We do not sell, trade, or otherwise transfer your personal
              information to outside parties except as described below:
            </p>
            <ul>
              <li>
                Service Providers: We may share your information with
                third-party service providers who perform services on our
                behalf.
              </li>
              <li>
                Legal Requirements: We may disclose your information if required
                to do so by law or in response to valid requests by public
                authorities.
              </li>
            </ul>
            <h5>Data Security</h5>
            <p>
              We use administrative, technical, and physical security measures
              to help protect your personal information. While we have taken
              reasonable steps to secure the personal information you provide to
              us, please be aware that no security measures are perfect or
              impenetrable.
            </p>
            <h5>Your Data Protection Rights</h5>
            <p>
              Depending on your location, you may have the following rights
              regarding your personal data:
            </p>
            <ul>
              <li>
                The right to access – You have the right to request copies of
                your personal data.
              </li>
              <li>
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate.
              </li>
              <li>
                The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions.
              </li>
              <li>
                The right to restrict processing – You have the right to request
                that we restrict the processing of your personal data, under
                certain conditions.
              </li>
              <li>
                The right to object to processing – You have the right to object
                to our processing of your personal data, under certain
                conditions.
              </li>
              <li>
                The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </li>
            </ul>


            <h5>Changes to This Privacy Policy</h5>
            <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
            </p>
            
            <h5>Contact Information</h5>
            <ul>
              <li>
                If you have any questions about these terms and conditions,
                please contact us at{" "}
                <a href="mailto:contact@evara-technologies.com">
                  contact@evara-technologies.com
                </a>
                .
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
