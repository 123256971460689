import React from "react";
import {
  HeaderConfig,
  IntroContentConfig,
  IntroThemeConfig,
  // appleAppStoreLink,
  appliedConfig,
  // googlePlayStoreLink,
} from "../config/commonConfig";
const CompanyOverview = () => {
  return (
    <>
      <section
        id="company_overview"
        className={
          "section " +
          (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
          appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
            ? "px-lg-4 "
            : " ")
        }
      >
        <div
          className={
            "container " +
            (appliedConfig.appliedIntro === IntroThemeConfig.Intro1 &&
            appliedConfig.appliedIntroContent === IntroContentConfig.Image
              ? "pt-0 "
              : " ")
          }
        >
          <h2 className="text-9 fw-600 text-center">Company Overview</h2>
          <hr className="heading-separator-line bg-modifiedGradient opacity-10 mx-auto" />
          <div className="row">
            <div className="col-lg-5 text-center">
              {" "}
              <img
                className="img-fluid"
                src="images/company/CompanyOverview.png"
                alt="Company Overview"
              />{" "}
            </div>
            <div className="col-lg-7 align-content-around text-center text-lg-start">
              <h2 className="text-6 mb-3 mt-4">
                Evara: Where Ideas Transform into Reality
              </h2>
              <p>
                We are a value driven group of experts with a blend of new
                generation techies and experienced domain experts. We strive to
                be relevant by unlearning and learning continuously. We value
                partnerships built on trust. You can count on us and we promise
                to be your best of your partners in success.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className={
          appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
          appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
            ? "bg-light"
            : ""
        }
      >
        <div className="row g-0">
          <div className="col-md-12 col-lg-6">
            <div className="p-0">
              <h3
                className="text-9 text-center fw-600 m-0 text-white"
                style={{
                  backgroundColor: "#082eb5",
                  padding: "100px 72px 100px calc(-615.5px + 50vw)",
                }}
              >
                Our Core Values
              </h3>
              <img style={{verticalAlign:'bottom'}}
                className="img-fluid"
                src="images/company/OurValues.webp"
                alt="Our Values"
              />
            </div>
          </div>
          <div
            className={
              "col-md-12 col-lg-6  " +
              (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
              appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
                ? "bg-white"
                : "bg-light")
            }
          >
            <section className="p-5">
              <h4>Client First</h4>
              <p className="small py-3 lh-base text-1">
                We put our clients at the heart of everything we do. By
                understanding your perspective, priorities, and budget, we
                partner with you to drive your growth and success. Your needs
                guide our approach, ensuring that we deliver solutions tailored
                to your unique goals.
              </p>
              <h4>Excellence</h4>
              <p className="small py-3 lh-base text-1">
                Our commitment to excellence is unwavering. We strive to achieve
                the highest standards in every aspect of our work, ensuring that
                each project reflects our dedication to quality and our pursuit
                of perfection.
              </p>

              <h4>Ownership</h4>
              <p className="small py-3 lh-base text-1">
                Reliability is our promise. Once we commit to a project, you can
                depend on us completely. We take full responsibility for
                delivering results, and you can trust that we will see it
                through to the end.
              </p>

              <h4>Innovation</h4>
              <p className="small py-3 lh-base text-1 mb-0">
                We are dedicated to staying ahead of the curve. By embracing the
                latest technologies and trends, we develop future-proof and
                scalable solutions. Our curiosity and drive for continuous
                learning fuel our ability to innovate and adapt in a rapidly
                evolving landscape.
              </p>
            </section>
          </div>
        </div>
      </section>

      <section
        className={"section text-white p-5"}
        style={{ background: "#082eb5" }}
      >
        <div className={"container "}>
          <div className="row">
            <div className="col-lg-12">
              <h2>Our differentiators</h2>
            </div>
            <div className="col-lg-3">
              <hr className="heading-separator-line bg-white opacity-10 w-100"></hr>
              <h3>Scale</h3>
              <p className="text-1">
                We have a broad pool of experts with diverse technological and
                domain backgrounds, ready to provide full-cycle implementation
                services at any project stage. Our resources are available on
                demand, ensuring your project has the right talent when needed.
              </p>
            </div>
            <div className="col-lg-3">
              <hr className="heading-separator-line bg-white opacity-10 w-100"></hr>
              <h3>Expertise</h3>
              <p className="text-1">
                Our team is comprised of seasoned professionals with extensive
                experience in delivering successful projects across various
                industries. Leveraging deep technical knowledge and industry
                insights, we are committed to providing tailored solutions that
                address the specific needs of each client.
              </p>
            </div>
            <div className="col-lg-3">
              <hr className="heading-separator-line bg-white opacity-10 w-100"></hr>
              <h3>Transparency</h3>
              <p className="text-1">
                We prioritize collaboration transparency by assigning certified
                project managers to every project. Through regular reporting,
                demos, and client access to project tracking tools, we keep you
                informed and involved throughout the entire process.
              </p>
            </div>
            <div className="col-lg-3">
              <hr className="heading-separator-line bg-white opacity-10 w-100"></hr>
              <h3>Flexibility</h3>
              <p className="text-1">
                We customize our engagement models to align with your
                requirements. Instead of a fixed rate, we offer an optimal
                project staffing approach, bringing in system architects for
                high-level consultations and providing a balanced mix of
                engineers suited to the task's complexity.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyOverview;
