import "./App.scss";
import Footer from "./components/Footer";
import { useState, useEffect } from "react";
import {
  HeaderConfig,
  IntroThemeConfig,
  appliedConfig,
} from "./config/commonConfig";
import { Tooltip } from "./components/Tooltip";
import ClassicHeader from "./components/themes/Headers/ClassicHeader";

// import Testimonials from "./components/Testimonials";
// import FAQs from "./components/FAQs";
// import Features from "./components/Features";
// import AppScreenshots from "./components/AppScreenshots";
// import Pricing from "./components/Pricing";
// import HowItWorks from "./components/HowItWorks";
// import GetTheApp from "./components/GetTheApp";
// import Subscribe from "./components/Subscribe";

import SideHeader from "./components/themes/Headers/SideHeader";
import DefaultHeader from "./components/themes/Headers/DefaultHeader";
import Intro1 from "./components/themes/Intro/Intro1";
import Intro2 from "./components/themes/Intro/Intro2";
import OverlayMenu from "./components/themes/Headers/OverlayMenu";
import { ToastContainer } from "react-toastify";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
//import Counter from "./components/Counter";
import AOS from 'aos';

import ServicesTabs from "./components/ServicesTabs";
import Products from "./components/Products";
import CompanyOverview from "./components/CompanyOverview";
import Contact from "./components/Contact";

function HomePage() {
    const appliedHeader = appliedConfig.appliedHeader;
  const appliedIntro = appliedConfig.appliedIntro;

  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [ selectedTab, setSelectedTab ] = useState('first')

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const getHeader = () => {
    if (appliedHeader === HeaderConfig.ClassicHeader) {
      return <ClassicHeader></ClassicHeader>;
    } else if (appliedHeader === HeaderConfig.DefaultHeader) {
      return <DefaultHeader setSelectedTab={setSelectedTab} selectedTab={selectedTab}></DefaultHeader>;
    } else if (
      appliedHeader === HeaderConfig.SideHeaderDark ||
      appliedHeader === HeaderConfig.SideHeaderLight
    ) {
      return (
        <SideHeader
          darkTheme={appliedHeader === HeaderConfig.SideHeaderDark}
        ></SideHeader>
      );
    } else if (appliedHeader === HeaderConfig.OverlayMenu) {
      return <OverlayMenu></OverlayMenu>;
    } else {
      return <DefaultHeader></DefaultHeader>;
    }
  };

  const getIntro = () => {
    if (appliedIntro === IntroThemeConfig.Intro1) {
      return <Intro1></Intro1>;
    } else if (appliedIntro === IntroThemeConfig.Intro2) {
      return <Intro2></Intro2>;
    } else {
      <Intro1></Intro1>;
    }
  };

  useEffect(() => {  
    AOS.init({  
      duration: 1000, // Animation duration in milliseconds  
      // once: false,     // Whether animation should happen only once - while scrolling down  
    });  
  }, []);
  return (
    <>
      <div
        className={
          appliedHeader === HeaderConfig.SideHeaderDark ||
            appliedHeader === HeaderConfig.SideHeaderLight
            ? "side-header"
            : ""
        }
        style={{ position: "relative" }}
      >
        <div id="main-wrapper">
          {getHeader()}

          <div id="content" role="main">
            {/* {appliedTheme === themeConfig.FullScreenMenu &&
              getFullScreenIntro()}
            {appliedTheme === themeConfig.StandardMenu &&
              getStandardMenuIntro()} */}

            {getIntro()}


            <ServicesTabs setSelectedTab={setSelectedTab} selectedTab={selectedTab}></ServicesTabs>

            <Products></Products>
            <CompanyOverview></CompanyOverview>
            
            <Contact></Contact>
          </div>
          <Footer handleNavClick={handleNavClick}></Footer>
        </div>
        {/* back to top */}
        <Tooltip text="Back to Top" placement="left">
          <span
            id="back-to-top"
            className="rounded-circle"
            style={{ display: scrollTopVisible ? "inline" : "none" }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <i className="fa fa-chevron-up"></i>
          </span>
        </Tooltip>
        <ToastContainer />
        <TermsAndConditions></TermsAndConditions>
        <PrivacyPolicy></PrivacyPolicy>
      </div>
    </>
  );
}

export default HomePage;
