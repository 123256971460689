import React, { useEffect, useState } from "react";
import {
  // HeaderConfig,
  IntroBgConfig,
  appliedConfig,
  scrollDuration,
} from "../../../config/commonConfig";
import { Link } from "react-scroll";

import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import { useNavigate, useLocation } from "react-router-dom";

function DefaultHeader(props) {
  let navigate = useNavigate();
  let location = useLocation();

  const { setSelectedTab, selectedTab } = props;
  const [stickyHeader, setStickyHeader] = useState(false);
  const [isNavModalClose, setIsNavModalClose] = useState(true);

  const checkScrollTop = () => {
    let header = document.getElementsByClassName("primary-menu");

    if (header) {
      if (
        document.body.scrollTop > 180 ||
        document.documentElement.scrollTop > 180
      ) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const [readURL, setReadURL] = useState(location.pathname);

  useEffect(() => {
    switch (readURL) {
      case "/consulting":
      case "/software_development":
      case "/enterprise_solutions":
        document.getElementsByClassName("our-services")[0].click();
        break;
      case "/our_services":
        document.getElementsByClassName("our-services")[0].click();
        break;
      case "/products":
        document.getElementsByClassName("productss")[0].click();
        break;
      case "/company_overview":
        document.getElementsByClassName("company-overview")[0].click();
        break;
      case "/contact":
        document.getElementsByClassName("contact")[0].click();
        break;
      default:
        break;
    }
  }, [readURL]);

  return (
    <header id="header" className="sticky-top-slide">
      {/* Navbar */}
      <nav
        style={{
          // background:'linear-gradient(360deg, white, transparent)',
          borderBottom: "0px",
        }}
        className={
          "primary-menu navbar navbar-expand-lg bg-transparent navbar-underline text-uppercase text-2 fw-600 " +
          (stickyHeader ? "sticky-on " : " ") +
          (appliedConfig.appliedIntroBg === IntroBgConfig.Default
            ? " "
            : "navbar-dark ")
        }
      >
        <div className="container-fluid">
          {/* Logo */}
          <a data-aos="ease" className="logo" href="/" title="Evara">
            <img
              src={
                stickyHeader
                  ? "images/logo/evara_logo2.png"
                  : "images/logo/evara_white.png"
              }
              alt="Evara Logo"
              style={{ maxWidth: "160px" }}
            />
          </a>
          {/* Logo End */}
          <button
            className={
              "navbar-toggler  " + (isNavModalClose ? "collapsed" : "show")
            }
            type="button"
            onClick={() => setIsNavModalClose(!isNavModalClose)}
          >
            <span />
            <span />
            <span />
          </button>
          <div
            id="header-nav"
            className={
              "collapse navbar-collapse justify-content-end " +
              (isNavModalClose ? "" : "show")
            }
          >
            <ul className="navbar-nav" data-aos="flip-left">
              {/* <li className="nav-item">
                <Link
                  className="nav-link "
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="home"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Home
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className={
                    "our_services nav-link text-capitalize our-services"
                  }
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  onMouseEnter={handleMouseEnter}
                  to="our_services"
                  onClick={(e) => {
                    if (location.pathname !== "/") {
                      navigate("/our_services");
                    }
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Services
                  <i
                    className={
                      "fa " +
                      (isOpen ? "fa-angle-up" : "fa-angle-down") +
                      " mx-1"
                    }
                  ></i>
                </Link>
              </li>
              <li className="nav-item" onMouseEnter={handleMouseLeave}>
                <Link
                  className={"nav-link text-capitalize productss"}
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="products"
                  onClick={(e) => {
                    if (location.pathname !== "/") {
                      navigate("/products");
                    }
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Products
                </Link>
              </li>
              <li className="nav-item" onMouseEnter={handleMouseLeave}>
                <Link
                  className={"nav-link text-capitalize company-overview"}
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="company_overview"
                  onClick={(e) => {
                    if (location.pathname !== "/") {
                      navigate("/company_overview");
                    }
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Company
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className="nav-link text-capitalize "
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="pricing"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Resources
                </Link>
              </li> */}
              <li
                data-aos="ease"
                onMouseEnter={handleMouseLeave}
                className="align-items-center h-auto ms-lg-3"
              >
                <Link
                  className={
                    "contact nav-link btn rounded-pill fw-600 shadow-none d-inline-block mt-3 mt-lg-0 smooth-scroll text-capitalize " +
                    (appliedConfig.appliedIntroBg === IntroBgConfig.Default
                      ? "btn-dark "
                      : "btn-modifiedBlue")
                  }
                  smooth="easeInOutQuint"
                  duration={scrollDuration}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="contact"
                  onClick={(e) => {
                    if (location.pathname !== "/") {
                      navigate("/contact");
                    }
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Navbar End */}

      {isOpen && (
        <div
          className="container-fluid d-none d-sm-block"
          style={{
            position: "fixed",
            top: 70,
            zIndex: 9999,
            background: "#fff",
          }}
          onMouseLeave={handleMouseLeave}
        >
          <div className="row">
            <div className="col-sm-3 offset-sm-3 pt-5 mt-5">
              <img src="\images\about-app.png" alt="" className="img-fluid" />
            </div>
            <div className="col-sm-3 pt-3 mt-3">
              <Link to="our_services">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="first"
                  activeKey={selectedTab}
                >
                  <Nav
                    onClick={handleMouseLeave}
                    variant="pills"
                    className="flex-column mobileTabs"
                    data-aos="slide-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                  >
                    <Nav.Item>
                      <Link
                        onClick={() => {
                          debugger;
                          if (location.pathname !== "/") {
                            navigate("/consulting");
                            setReadURL("/consulting");
                            if(location.pathname !== readURL){
                              document.getElementsByClassName("our-services")[0].click();
                            }
                          }
                          else{
                            document.getElementsByClassName("our-services")[0].click();
                          }
                          setIsNavModalClose(true);
                          setIsOpen(false);
                        }}
                      >
                        <Nav.Link
                          to="/"
                          eventKey="first"
                          onClick={() => setSelectedTab("first")}
                        >
                          Consulting
                        </Nav.Link>
                      </Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Link
                        onClick={() => {
                          if (location.pathname !== "/") {
                            navigate("/software_development");
                            setReadURL("/software_development");
                            if(location.pathname !== readURL){
                              document.getElementsByClassName("our-services")[0].click();
                            }
                          }
                          else{
                            document.getElementsByClassName("our-services")[0].click();
                          }
                          setIsNavModalClose(true);
                          setIsOpen(false);
                        }}
                      >
                      <Nav.Link
                        eventKey="second"
                        onClick={() => setSelectedTab("second")}
                      >
                        Software Development
                      </Nav.Link>
                      </Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link
                        eventKey="three"
                        onClick={() => setSelectedTab("three")}
                      >
                        Digital Marketing
                      </Nav.Link>
                    </Nav.Item> 
                    <Nav.Item>
                      <Nav.Link
                        eventKey="four"
                        onClick={() => setSelectedTab("four")}
                      >
                        Cloud
                      </Nav.Link>
                    </Nav.Item>*/}
                    <Nav.Item>
                    <Link
                        onClick={() => {
                          if (location.pathname !== "/") {
                            navigate("/enterprise_solutions");
                            setReadURL("/enterprise_solutions");
                            if(location.pathname !== readURL){
                              document.getElementsByClassName("our-services")[0].click();
                            }
                          }
                          else{
                            document.getElementsByClassName("our-services")[0].click();
                          }
                          setIsNavModalClose(true);
                          setIsOpen(false);
                        }}
                      >
                      <Nav.Link
                        eventKey="five"
                        onClick={() => setSelectedTab("five")}
                      >
                        Enterprise Solutions
                      </Nav.Link>
                      </Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link
                        eventKey="six"
                        onClick={() => setSelectedTab("six")}
                      >
                        Technical Staffing
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="seven"
                        onClick={() => setSelectedTab("seven")}
                      >
                        AI/ML
                      </Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                </Tab.Container>
              </Link>
              {/* <ul className="list-unstyled fs-6 fw-semibold">
                <li eventKey="first">Consulting</li>
                <li eventKey="second">Software Development</li>
                <li>Digital Marketing</li>
                <li>Cloud</li>
                <li>Enterprise Solution</li>
                <li>Technical Staffing</li>
                <li>AI/ML</li>
              </ul> */}
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

export default DefaultHeader;
