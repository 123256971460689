import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import "react-toastify/dist/ReactToastify.css";
import {
  HeaderConfig,
  appliedConfig,
  emailjsConfig,
} from "../config/commonConfig";

import PropTypes from "prop-types";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";

const CountrySelect = ({ value, onChange, labels, ...rest }) => (
  <select
    id="phoneCountry"
    name="phoneCountry"
    {...rest}
    value={value}
    onChange={(event) => onChange(event.target.value || undefined)}
  >
    <option value="">{labels["ZZ"]}</option>
    {getCountries().map((country) => (
      <option key={country} value={labels[country] + ' +'+getCountryCallingCode(country)}>
        {labels[country]} +{getCountryCallingCode(country)}
      </option>
    ))}
  </select>
);

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
};

const Contact = () => {
  // const [value, setValue] = useState();
  const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);

  const [country, setCountry] = useState();

  const sendEmail = (e) => {
    e.preventDefault();
    debugger;
    if (validation(e)) {
      setSendingMail(true);
      emailjs
        .sendForm(
          emailjsConfig.serviceId,
          emailjsConfig.templateId,
          form.current,
          emailjsConfig.userId
        )
        .then(
          (result) => {
            document.getElementById("contact-form").reset();
            toast.success("Message sent successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            console.log(result.text);
            setSendingMail(false);
          },
          (error) => {
            toast.error("Something went wrong!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            console.log(error.text);
            setSendingMail(false);
          }
        );
    }
    // setValue("");
  };

  const validation = (e) => {
    // console.log("Information:", e.target['company_name'].value);
    const nameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    debugger;

    if (!nameRegex.test(e.target["name"].value)) {
      toast.error("Please enter valid name!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!phoneRegex.test(e.target["phone"].value.replace(/\D/g, ""))) {
      toast.error("Please enter valid phone number!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (!emailRegex.test(e.target["email"].value)) {
      toast.error("Please enter valid email address!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    if (e.target["message"].value.length < 20) {
      toast.error(
        "Please enter a message that contain at least 20 characters!",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      return false;
    }

    return true;
  };

  return (
    <section
      id="contact"
      className={
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "bg-light"
          : ""
      }
    >
      <div className="row g-0">
        <div className="col-md-6 col-lg-7">
          <div className="section px-4 px-sm-5 mx-lg-5">
            <h2 className="fw-600 text-center mb-3">Contact Us</h2>
            <hr className="heading-separator-line bg-modifiedGradient opacity-10 mx-auto" />
            <p className="lead text-center mb-5">
              We will get back to you in less than 24 hours.
            </p>
            <form id="contact-form" ref={form} onSubmit={sendEmail}>
              <div className="mb-3">
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="form-control border-2"
                  required
                  placeholder="Name"
                />
              </div>
              <div className="mb-3">
                <input
                  id="company_name"
                  name="company_name"
                  type="text"
                  className="form-control border-2"
                  required
                  placeholder="Company Name"
                />
              </div>
              <div className="mb-3">
                <CountrySelect
                  labels={en}
                  defaultValue="United States +1"
                  value={country}
                  onChange={setCountry}
                  className="border-2 form-control d-inline"
                  style={{ width: "50%" }}
                />
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  className="form-control border-2 d-inline"
                  required
                  placeholder="Phone"
                  style={{ width: "50%" }}
                  rules={{ required: true, validate: isPossiblePhoneNumber }}
                />
                {/* <PhoneInput
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                  value={value}
                  onChange={setValue}
                  defaultCountry="US"
                  className="form-control border-2"
                  rules={{ required: true, validate: isPossiblePhoneNumber }}
                  required
                /> */}
              </div>
              <div className="mb-3">
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="form-control border-2"
                  required
                  placeholder="Email"
                />
              </div>
              <div className="mb-3">
                <textarea
                  id="message"
                  name="message"
                  className="form-control border-2"
                  rows={3}
                  required
                  placeholder="Message........"
                  defaultValue={""}
                />
              </div>
              <p className="text-center mt-4 mb-0">
                <button
                  id="submit-btn"
                  className="btn btn-primary text-uppercase bg-modifiedBlue"
                  type="submit"
                >
                  {sendingMail ? (
                    <>
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm align-self-center me-2"
                      ></span>
                      Sending.....
                    </>
                  ) : (
                    <>{"Send Message"}</>
                  )}
                </button>
              </p>
            </form>
          </div>
        </div>
        <div
          className={
            "col-md-6 col-lg-5  " +
            (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
            appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
              ? "bg-white"
              : "bg-light")
          }
        >
          <section className="section px-4 px-sm-5 mx-lg-4">
            <h2 className="fw-600 mb-3">Our Headquarter</h2>
            <hr className="heading-separator-line bg-modifiedGradient opacity-10" />
            <p className="lead">
              For Customer Support and Query, Get in touch with us.
            </p>
            <div className="featured-box style-1">
              <div className="featured-box-icon text-6">
                {" "}
                <i className="fas fa-map-marker-alt" />
              </div>
              <h6>EVARA TECHNOLOGIES PRIVATE LIMITED</h6>
              <p>
                HD 102 WEWORK CINNABAR HILLS, EMBASSY GOLF LINKS,
                <br />
                Domlur, Bangalore North,
                <br />
                Bangalore- 560071
                <br />
                Karnataka, India{" "}
              </p>
            </div>
            <div className="featured-box style-1">
              <div className="featured-box-icon text-6">
                {" "}
                <i className="fas fa-phone" />{" "}
              </div>
              <h3>Telephone</h3>
              <p>
                USA: <a href="tel:+15513994648">(+1) 5513994648</a> <br />{" "}
                India: <a href="tel:+919137422652">(+91) 9137422652</a>
              </p>
            </div>
            <div className="featured-box style-1">
              <div className="featured-box-icon text-6">
                {" "}
                <i className="fas fa-envelope" />{" "}
              </div>
              <h3>Business Inquiries</h3>
              <p>
                <a href="mailto:contact@evara-technologies.com">
                  contact@evara-technologies.com
                </a>
              </p>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Contact;
