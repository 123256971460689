import React from "react";
import { HeaderConfig, appliedConfig } from "../config/commonConfig";

import "../css/productsStyle.css";

function Products() {
  return (
    <section
      id="products"
      className={
        "section bg-light " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div className="container">
        <h2 className="text-9 fw-600 text-center">Products</h2>
        <hr className="heading-separator-line bg-modifiedGradient opacity-10 mx-auto" />

        <div className="row gy-4">
          <div className="col-sm-6 col-lg-4">
            <div className="card text-center border-0 shadow-md">
              <div className="card-header text-6 fw-500 text-white border-bottom-0 bg-modifiedBlue py-4">
                Smart Agent<br></br> Chatbot<br></br>
                <small className="text-0"> (Coming Soon)</small>
              </div>
              <div className="card-body">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        className="img-fluid"
                        src="/images/product/SmartAgentChatbot.png"
                        alt="Smart Agnet Chatbot"
                        style={{ height: "inherit", width: "inherit" }}
                      />
                    </div>
                    <div className="flip-card-back align-content-around text-1">
                      <p>
                        Our AI-powered Smart Agent chatbot delivers intelligent,
                        context-aware responses, enhancing customer interactions
                        across platforms. It continuously learns and adapts,
                        automating customer service with increasing accuracy and
                        efficiency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="card text-center border-0 shadow-md">
              <div className="card-header text-6 fw-500 text-white border-bottom-0 bg-modifiedBlue py-4">
                E-Commerce<br></br> Platform<br></br>
                <small className="text-0"> (Coming Soon)</small>
              </div>
              <div className="card-body">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        className="img-fluid"
                        src="/images/product/eCommercePlatform.png"
                        alt="E-Commerce Platform"
                        style={{ height: "inherit", width: "inherit" }}
                      />
                    </div>
                    <div className="flip-card-back align-content-around text-1">
                      <p>
                        Our e-commerce platform offers a scalable, customizable
                        online shopping experience. With features like secure
                        payment gateways and advanced inventory management, it’s
                        designed to grow with your business, providing seamless
                        integration and user-friendly navigation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="card text-center border-0 shadow-md">
              <div className="card-header text-6 fw-500 text-white border-bottom-0 bg-modifiedBlue py-4">
                Learning Management System (LMS) Platform<br></br>
                <small className="text-0"> (Coming Soon)</small>
              </div>
              <div className="card-body">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        className="img-fluid"
                        src="/images/product/LMS.png"
                        alt="LMS Platform"
                        style={{ height: "inherit", width: "inherit" }}
                      />
                    </div>
                    <div className="flip-card-back align-content-around text-1">
                      <p>
                        Our LMS platform incorporates AI and ML to provide
                        intelligent, personalized learning experiences. It
                        features a user-friendly interface, customizable
                        courses, and robust analytics, ensuring an engaging and
                        effective learning environment tailored to individual
                        needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Products;
