import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage'
import SoftwareDevelopmentPage from './SoftwareDevelopmentPage'

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/software-development" element={<SoftwareDevelopmentPage />} />
        
        {/* Add more routes as needed */}
        <Route path="*" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
