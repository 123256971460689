import * as React from "react";
import PropTypes from "prop-types";
// import Tab from '@mui/material/Tab';
import {
  HeaderConfig,
  IntroContentConfig,
  IntroThemeConfig,
  appliedConfig,
  //   googlePlayStoreLink,
} from "../config/commonConfig";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";

import { Link } from "react-scroll";


import { useNavigate, useLocation } from "react-router-dom";

import "../css/modifiedStyleSheet.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ServicesTabs(props) {
  const { setSelectedTab, selectedTab } = props;
  let navigate = useNavigate();
  let location = useLocation();

  switch (location.pathname) {
    case "/consulting":
      setSelectedTab("first");
      break;
    case "/software_development":
      setSelectedTab("second");
      break;
    case "/enterprise_solutions":
      setSelectedTab("five");
      break;
    default:
      break;
  }

  return (
    <section
      id="our_services"
      className={
        "section " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div
        className={
          "container " +
          (appliedConfig.appliedIntro === IntroThemeConfig.Intro1 &&
          appliedConfig.appliedIntroContent === IntroContentConfig.Image
            ? "pt-0 "
            : " ")
        }
      >
        <h2
          className="text-9 fw-600"
          data-aos="flip-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          Our Services
        </h2>
        <hr className="heading-separator-line bg-modifiedGradient opacity-10" />

        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="first"
          activeKey={selectedTab}
        >
          <Row>
            <Col sm={3}>
              <Nav
                variant="pills"
                className="flex-column mobileTabs"
                data-aos="slide-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              >
                <Nav.Item>
                  <Link
                    onClick={() => {
                      if (location.pathname !== "/") {
                        navigate("/consulting");
                      }
                    }}
                  >
                    <Nav.Link
                      className="consulting"
                      eventKey="first"
                      onClick={() => setSelectedTab("first")}
                    >
                      Consulting
                    </Nav.Link>
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    onClick={() => {
                      if (location.pathname !== "/") {
                        navigate("/software_development");
                      }
                    }}
                  >
                    <Nav.Link
                      eventKey="second"
                      onClick={() => setSelectedTab("second")}
                    >
                      Software Development
                    </Nav.Link>
                  </Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="three" onClick={() => setSelectedTab('three')}>Digital Marketing</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="four" onClick={() => setSelectedTab('four')}>Cloud</Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Link
                    onClick={() => {
                      if (location.pathname !== "/") {
                        navigate("/enterprise_solutions");
                      }
                    }}
                  >
                    <Nav.Link
                      eventKey="five"
                      onClick={() => setSelectedTab("five")}
                    >
                      Enterprise Solutions
                    </Nav.Link>
                  </Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="six" onClick={() => setSelectedTab('six')}>Technical Staffing</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="seven" onClick={() => setSelectedTab('seven')}>AI/ML</Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content
                data-aos="slide-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="500"
              >
                <Tab.Pane eventKey="first">
                  <div className="row">
                    <div className="col-md-12">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Gap Analysis</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-9 align-content-around">
                                Gap can be seen as an opportunity for cost
                                optimization and revenue/margin improvements.
                                Great businesses strive to improve efficiencies
                                by continuously looking for gaps and filling
                                those. Imagine an apparently small gap that
                                impacts the productivity, quality, compliance
                                related requirements. How about evaluating your
                                current state of business processes with help of
                                third party domain experts that brings fresh
                                view without any prejudices and biases - it
                                helps!!
                              </div>
                              <div className="col-md-3 order-first order-sm-last">
                                <img
                                  alt="Evara Img"
                                  className="img-fluid img-thumbnail"
                                  src="\images\services\consulting\GapAnalysis.jpeg"
                                ></img>
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>
                            Business Process Reengineering
                          </Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-12">
                                Best defined processes of the past need a relook
                                as new software platforms and products emerge on
                                horizon and these processes look like energy
                                suckers - costing more resources in competitive
                                market. Revise your processes to leverage the
                                latest technological developments, newer process
                                improvement techniques and our experts' domain
                                knowledge. It helps!!
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Architecture Design</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-12">
                                Best designed software architecture pays up in
                                the long run. Your business is there to stay
                                longer! whether your architecture design is
                                capable of sustaining long enough? Use our
                                expertise in latest technologies to design solid
                                architecture. It helps!!
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-12">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>IT Strategy And Planning</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-3">
                                <img
                                  alt="Evara Img"
                                  className="img-fluid img-thumbnail"
                                  src="\images\services\consulting\IT_Strategy_Planning.jpeg"
                                ></img>
                              </div>
                              <div className="col-md-9 align-content-around">
                                Your success is our success. We partner with you
                                to define your long term, goal oriented strategy
                                and a plan to execute it. It helps!!
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="second">
                  <div className="row">
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Custom Software Development</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-12 align-content-around">
                                Digitise and digitalise your business for
                                building efficiencies across the functions of
                                your business to get multifold benefits at
                                comparatively lesser costs and time. Building
                                optimum custom solutions go a long way in
                                beating the competition without any great deal.
                                Do it with help of our team and we promise to
                                deliver the best. It helps!!
                              </div>
                              <div className="col-1 offset-10">
                                <a href="/software-development">
                                  <i className="fa fa-arrow-right fs-2"></i>
                                </a>
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>
                            Mobile Application Development
                          </Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-12 align-content-around">
                                Everyone wants mobility - including your
                                employees, partners and vendors. We make them
                                comfortable by providing efficinet and user
                                friendly mobile apps using latest backend and
                                frontend technologies. Users will use it more
                                effectively, on the go.. It helps!!
                              </div>
                              <div className="col-1 offset-10">
                                <a href="/software-development">
                                  <i className="fa fa-arrow-right fs-2"></i>
                                </a>
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-12">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Web Application Development</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-3">
                                <img
                                  alt="Evara Img"
                                  className="img-fluid img-thumbnail"
                                  src="\images\services\software_development\WebAppDevelopment.jpeg"
                                ></img>
                              </div>
                              <div className="col-md-9 align-content-around">
                                In Web Application Development, our team creates
                                dynamic, high-performance web solutions tailored
                                to your business needs. We ensure responsive
                                design, seamless functionality, and a superior
                                user experience. Trust us to deliver web
                                applications that drive engagement and growth.
                              </div>
                              <div className="col-1 offset-11">
                                <a href="/software-development">
                                  <i className="fa fa-arrow-right fs-2"></i>
                                </a>
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Product Development</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-12 align-content-around">
                                You have a software product in mind? And need
                                help to design and develop it? We will help you
                                by providing the product lifecycle support
                                starting from conceving to retirement. Our
                                experrtise will help in creating best of the
                                class product. It helps!
                              </div>
                              <div className="col-1 offset-10">
                                <a href="/software-development">
                                  <i className="fa fa-arrow-right fs-2"></i>
                                </a>
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>New Tech Solutions</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-12 align-content-around">
                                Today's new tech will be tomorrow's old tech!
                                However, no one can afford to not adopt the
                                trend and reap the benefits by investing these
                                technologies. Whether it is Cloud and
                                CICD/Agile, AIML, Robotics or IOT, we provide
                                expertise for you to get the best of these.
                                Adopt these. It helps!!
                              </div>
                              <div className="col-1 offset-10">
                                <a href="/software-development">
                                  <i className="fa fa-arrow-right fs-2"></i>
                                </a>
                              </div>
                              {/* <div className="col-md-3 order-first order-sm-last">
                                <img
                                  alt="Evara Img"
                                  className="img-fluid img-thumbnail"
                                  src="\images\services\software_development\NewTechSolutions.jpeg"
                                ></img>
                              </div> */}
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="three">
                  <div className="row">
                    <div className="col-md-12">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>
                            Website Design and Development
                          </Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-9 align-content-around">
                                Create a compelling online presence with our
                                Website Design and Development services. We
                                craft visually stunning, user-centric websites
                                that are optimized for performance and tailored
                                to your business needs. Elevate your brand with
                                a site that engages and converts visitors.
                              </div>
                              <div className="col-md-3 order-first order-sm-last">
                                <img
                                  alt="Evara Img"
                                  className="img-fluid img-thumbnail"
                                  src="\images\services\digital_marketing\WebsiteDesignDevelopment.jpeg"
                                ></img>
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Search Engine Optimization</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-12 align-content-around">
                                Boost your online visibility with our Search
                                Engine Optimization (SEO) services. We optimize
                                your website to rank higher in search engine
                                results, drive organic traffic, and increase
                                your online presence. Trust us to enhance your
                                search rankings and attract more qualified
                                leads.
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Social Media Marketing</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-12 align-content-around">
                                Expand your reach with our Social Media
                                Marketing services. We develop and execute
                                strategies to engage your audience across
                                various platforms, build brand awareness, and
                                drive meaningful interactions. Leverage social
                                media to connect with customers and grow your
                                business.
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="col-md-12">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Pay Per Click Marketing</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-3">
                                <img
                                  alt="Evara Img"
                                  className="img-fluid img-thumbnail"
                                  src="\images\services\digital_marketing\PPC.jpeg"
                                ></img>
                              </div>
                              <div className="col-md-9 align-content-around">
                                Maximize your ROI with our Pay Per Click (PPC)
                                Marketing services. We create targeted ad
                                campaigns that drive immediate traffic and
                                conversions. Our data-driven approach ensures
                                effective budgeting and high performance,
                                delivering measurable results and boosting your
                                online presence.
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="four">
                  <div className="row">
                    <div className="col-md-12">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Cloud Consulting</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-9 align-content-around">
                                Leverage our Cloud Consulting expertise to
                                navigate your cloud journey. We provide
                                strategic guidance on selecting and implementing
                                the best cloud solutions, ensuring you optimize
                                your technology for efficiency and scalability.
                              </div>
                              <div className="col-md-3 order-first order-sm-last">
                                <img
                                  alt="Evara Img"
                                  className="img-fluid img-thumbnail"
                                  src="\images\services\cloud\CloudConsulting.jpeg"
                                ></img>
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Cloud Application Development</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-12 align-content-around">
                                Transform your business with our Cloud
                                Application Development services. We design and
                                build scalable, secure applications tailored to
                                your needs, enhancing performance and
                                flexibility to drive innovation and growth.
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Cloud Migration</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-12 align-content-around">
                                Seamlessly transition to the cloud with our
                                Cloud Migration services. We handle the
                                end-to-end process of moving your applications,
                                data, and infrastructure, ensuring minimal
                                disruption and maximum efficiency for a smooth
                                migration.
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-12">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Cloud Optimization</Card.Title>
                          <Card.Text>
                            <div className="row">
                              <div className="col-md-3">
                                <img
                                  alt="Evara Img"
                                  className="img-fluid img-thumbnail"
                                  src="\images\services\cloud\CloudOptimization.jpeg"
                                ></img>
                              </div>
                              <div className="col-md-9 align-content-around">
                                Maximize the value of your cloud investments
                                with our Cloud Optimization services. We analyze
                                and enhance your cloud environment to improve
                                performance, reduce costs, and ensure your
                                infrastructure operates at peak efficiency.
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="five">
                  <div className="row">
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>ERP Consulting</Card.Title>
                          <Card.Text>
                            Leverage our Cloud Consulting expertise to navigate
                            your cloud journey. We provide strategic guidance on
                            selecting and implementing the best cloud solutions,
                            ensuring you optimize your technology for efficiency
                            and scalability.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>ERP Implementation</Card.Title>
                          <Card.Text>
                            Ensure a successful ERP transformation with our ERP
                            Implementation services. We manage the entire
                            process from planning and configuration to
                            deployment and training, ensuring a seamless
                            integration that boosts productivity and aligns with
                            your business goals.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>CRM Consulting</Card.Title>
                          <Card.Text>
                            Optimize your customer relationships with our CRM
                            Consulting services. We offer expert advice on
                            selecting and implementing CRM solutions tailored to
                            your business needs, enhancing customer interactions
                            and driving growth.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>CRM Implementation</Card.Title>
                          <Card.Text>
                            Achieve seamless CRM integration with our CRM
                            Implementation services. We handle the full
                            implementation process, from setup and customization
                            to training and support, ensuring your CRM system
                            effectively improves customer management and
                            supports your business objectives.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="six">
                  <div className="row">
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Full-Service Development</Card.Title>
                          <Card.Text>
                            Our full-service development model offers you a
                            dedicated offshore development center tailored
                            specifically for your project. You can oversee team
                            selection and communication or streamline engagement
                            through a project manager while maintaining
                            transparency and visibility throughout the process.
                            We ensure efficient team operation and take full
                            responsibility for all deliverables, enabling you to
                            focus on your core business while we handle the
                            development intricacies.{" "}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>In-House Team Augmentation</Card.Title>
                          <Card.Text>
                            Enhance your existing team with our skilled
                            engineering, testing, and support specialists. Our
                            experts seamlessly integrate into your established
                            processes and methodologies, providing additional
                            domain-specific knowledge and technical
                            competencies. This model allows you to leverage our
                            R&D and engineering expertise while benefiting from
                            the synergy of our combined resources, maximizing
                            the efficiency and capability of your in-house team.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>DevOps Enablement</Card.Title>
                          <Card.Text>
                            Accelerate your product development with our DevOps
                            services. Our DevOps experts manage the full
                            lifecycle of your IT assets, from building and
                            testing to shipping and monitoring. We streamline
                            and automate each stage of your software delivery
                            process, including infrastructure management, CI/CD,
                            and deployment, reducing time-to-market and
                            enhancing product scalability.{" "}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card
                        className="bg-modifiedBg mb-3"
                        data-aos="fade-up"
                        data-aos-easing="ease-in"
                        data-aos-duration="1000"
                      >
                        <Card.Body>
                          <Card.Title>Dedicated Development Center</Card.Title>
                          <Card.Text>
                            Establish a dedicated development center with our
                            comprehensive support. We provide access to
                            cutting-edge infrastructure, technological tools,
                            and skilled professionals, ensuring optimal team
                            productivity and process alignment. This model
                            supports both in-house and offshore operations,
                            facilitating seamless communication and process
                            optimization to meet your specific project needs.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </section>
  );
}
