import React from "react";
import { HeaderConfig, appliedConfig } from "../config/commonConfig";

function Features() {
  return (
    <section
      id="features"
      className={
        "section " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div className="container">
        <h2 className="text-9 fw-600 text-center">Technologies</h2>
        <hr className="heading-separator-line bg-primary opacity-10 mx-auto" />
        <p className="lead text-center mb-5">we work with...</p>
        <div className="row">
          <div className="col-lg-4 align-self-center">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-pencil-ruler" />
                  </div>
                  <h3>Programming Languages</h3>
                  <p>Javascript, Typescript, Java, Python, PHP, Swift</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-cloud-upload-alt" />
                  </div>
                  <h3>Frameworks & Libraries</h3>
                  <p>
                    React, Express, Angular, Vue.js, Spring, Flask, Django,
                    Laravel
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box featured-box-reverse-xl style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-tags" />
                  </div>
                  <h3>Mobile Technologies</h3>
                  <p>React Native, Flutter</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center mb-4 mb-xl-0">
            <img
              alt=""
              src="images/app-showcase-feature.png"
              className="img-fluid"
            />
          </div>
          <div className="col-lg-4 align-self-center">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-lock" />
                  </div>
                  <h3>Cloud Technologies</h3>
                  <p>AWS, Microsoft Azure, Google Cloud Platform</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-paper-plane" />
                  </div>
                  <h3>Platforms</h3>
                  <p>Salesforce, Odoo, Microsoft Dynamics 365</p>
                </div>
              </div>
              {/* <div className="col-md-6 col-lg-12">
                <div className="featured-box style-3 mb-5">
                  <div className="featured-box-icon bg-body-secondary rounded-circle">
                    <i className="fas fa-sync-alt" />
                  </div>
                  <h3>Free Updates</h3>
                  <p>
                    Lisque persius interesset his et, in quot quidam persequeris
                    vim, ad mea essent possim iriure.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <h3>Software Delivery Framework:</h3>
            <p>
              Evara manages the software development process from inception and
              can take over product implementation at any stage of the
              development lifecycle. We also assist companies post-deployment,
              providing long-term maintenance and support services for
              implemented solutions.
            </p>
          </div>
          <div className="col-lg-12 text-center">
          <svg
              class="big-scheme desktop-tablet-only application-development-framework-tech img-fluid img-thumbnail"
              width="1216"
              height="200"
              viewBox="0 0 1216 200"
              fill="none"
            >
              <rect width="1216" height="200" fill="#F8F8F8"></rect>
              <path
                d="M129 141V88"
                stroke="#C4C4C4"
                stroke-width="2"
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-dasharray="0.5 7"
              ></path>
              <path
                d="M429 141V88"
                stroke="#C4C4C4"
                stroke-width="2"
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-dasharray="0.5 7"
              ></path>
              <path
                d="M739 141V88"
                stroke="#C4C4C4"
                stroke-width="2"
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-dasharray="0.5 7"
              ></path>
              <path
                d="M908 141V88"
                stroke="#C4C4C4"
                stroke-width="2"
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-dasharray="0.5 7"
              ></path>
              <path
                d="M1216 88H-2.6226e-06"
                stroke="#C4C4C4"
                stroke-width="2"
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-dasharray="0.5 7"
              ></path>
              <path
                d="M1080 88H72"
                stroke="url(#paint0_linear_5654_5992)"
                stroke-width="4"
              ></path>
              <circle cx="72" cy="88" r="8" fill="#12BCBC"></circle>
              <circle cx="346" cy="88" r="8" fill="#699ABA"></circle>
              <circle cx="476" cy="88" r="8" fill="#9489B9"></circle>
              <circle
                cx="8"
                cy="8"
                r="8"
                transform="matrix(-1 0 0 1 689 80)"
                fill="#C66A9C"
              ></circle>
              <circle cx="847" cy="88" r="8" fill="#DB557D"></circle>
              <circle cx="1080" cy="88" r="8" fill="#F8374E"></circle>
              <text
                fill="black"
                style={{whiteSpace:'pre'}}
                font-family="November Pro"
                font-size="16"
                font-weight="bold"
                letter-spacing="0em"
              >
                <tspan x="67" y="56.488">
                  Analysis
                </tspan>
              </text>
              <text
                fill="black"
                
                style={{whiteSpace:'pre'}}
                font-family="November Pro"
                font-size="16"
                font-weight="bold"
                letter-spacing="0em"
              >
                <tspan x="341" y="56.488">
                  Design
                </tspan>
              </text>
              <text
                fill="black"
                
                style={{whiteSpace:'pre'}}
                font-family="November Pro"
                font-size="16"
                font-weight="bold"
                letter-spacing="0em"
              >
                <tspan x="471" y="56.488">
                  Development{" "}
                </tspan>
              </text>
              <text
                fill="black"
                
                style={{whiteSpace:'pre'}}
                font-family="November Pro"
                font-size="16"
                font-weight="bold"
                letter-spacing="0em"
              >
                <tspan x="676" y="56.488">
                  Quality assurance
                </tspan>
              </text>
              <text
                fill="black"
                
                style={{whiteSpace:'pre'}}
                font-family="November Pro"
                font-size="16"
                font-weight="bold"
                letter-spacing="0em"
              >
                <tspan x="842" y="56.488">
                  Deployment
                </tspan>
              </text>
              <text
                fill="black"
                
                style={{whiteSpace:'pre'}}
                font-family="November Pro"
                font-size="16"
                font-weight="bold"
                letter-spacing="0em"
              >
                <tspan x="1075" y="56.488">
                  Support
                </tspan>
              </text>
              <text
                fill="#323232"
                
                style={{whiteSpace:'pre'}}
                font-family="November Pro"
                font-size="16"
                letter-spacing="0px"
              >
                <tspan x="144" y="153.488">
                  Requirements testing
                </tspan>
              </text>
              <text
                fill="#323232"
                
                style={{whiteSpace:'pre'}}
                font-family="November Pro"
                font-size="16"
                letter-spacing="0px"
              >
                <tspan x="444" y="153.488">
                  UX/UI testing
                </tspan>
              </text>
              <text
                fill="#323232"
                
                style={{whiteSpace:'pre'}}
                font-family="November Pro"
                font-size="16"
                letter-spacing="0px"
              >
                <tspan x="754" y="153.488">
                  Stabilization
                </tspan>
              </text>
              <text
                fill="#323232"
                
                style={{whiteSpace:'pre'}}
                font-family="November Pro"
                font-size="16"
                letter-spacing="0px"
              >
                <tspan x="923" y="153.488">
                  Acceptance testing
                </tspan>
              </text>
              <circle
                cx="129"
                cy="146"
                r="5"
                stroke="#20B7BC"
                stroke-width="2"
              ></circle>
              <circle
                cx="429"
                cy="146"
                r="5"
                stroke="#838FB9"
                stroke-width="2"
              ></circle>
              <circle
                cx="739"
                cy="146"
                r="5"
                stroke="#CA6695"
                stroke-width="2"
              ></circle>
              <circle
                cx="5"
                cy="5"
                r="5"
                transform="matrix(-1 0 0 1 913 141)"
                stroke="#E54C6F"
                stroke-width="2"
              ></circle>
              <defs>
                <linearGradient
                  id="paint0_linear_5654_5992"
                  x1="1080"
                  y1="88.5"
                  x2="72"
                  y2="88.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F8374E"></stop>
                  <stop offset="0.5" stop-color="#B27CB8"></stop>
                  <stop offset="1" stop-color="#0ABFBC"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-4 d-flex">
            <div className="card card-success">
              <div className="card-header">
                <h5 className="card-title">Business Analysis</h5>
              </div>
              <div className="card-body">
                <p>
                  Our business analysts gather, analyse, and structure
                  customers’ functional and non-functional requirements,
                  followed by developing a detailed solution roadmap.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 d-flex">
            <div className="card card-success">
              <div className="card-header">
                <h5 className="card-title">Prototyping</h5>
              </div>
              <div className="card-body">
                <p>
                  Using deliverables from the business analysis phase, our team
                  creates wireframes and prototypes to assess software
                  feasibility and gather early feedback.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 d-flex">
            <div className="card card-success">
              <div className="card-header">
                <h5 className="card-title">Product Design</h5>
              </div>
              <div className="card-body">
                <p>
                  Our UI/UX designers transform requirements and prototypes into
                  custom software designs. We define user personas, set up
                  product usage scenarios, navigation, and interactive
                  components.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-4 d-flex">
            <div className="card card-success">
              <div className="card-header">
                <h5 className="card-title">Development</h5>
              </div>
              <div className="card-body">
                <p>
                  We develop tailored solutions using Agile principles, breaking
                  the process into short iterations for quick deliverables,
                  while ensuring easy management, integration, and scalability.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 d-flex">
            <div className="card card-success">
              <div className="card-header">
                <h5 className="card-title">QA & Testing</h5>
              </div>
              <div className="card-body">
                <p>
                  Our development process includes continuous quality assurance
                  and testing to evaluate software performance, behaviour, and
                  the functional health of all components.{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 d-flex">
            <div className="card card-success">
              <div className="card-header">
                <h5 className="card-title">Maintenance and Support</h5>
              </div>
              <div className="card-body">
                <p>
                  We provide various levels of software maintenance and support,
                  addressing challenges like software upgrades, functional
                  improvements, and performance monitoring.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
